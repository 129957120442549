import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { ApiResult, VINReview } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets the VIN review table view.
   * @param params - the parameters for the table view.
   * @returns the TableViewResult of VIN reviews.
   */
  getVINReviews(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<VINReview>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    const host = baseUrl()
    let query = `page=${page}&pageSize=${pageSize}&visibility=ALL`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    return httpService.get(`https://${host}/tables/vinReviews?${query}`)
  },

  /**
   * Accepts a VIN review.
   * @param id - the VIN review ID.
   * @returns an APIResult indicating if accepting the VIN review was successful.
   */
  acceptVinReview(id: number): Promise<AxiosResponse<ApiResult>> {
    return httpService.post(`https://${baseUrl()}/vehicles/vinReviews/${id}/accept`, null);
  },

  /**
   * Rejects a VIN review.
   * @param id - the VIN review ID.
   * @returns an APIResult indicating if rejecting the VIN review was successful.
   */
  rejectVINReview(id: number, rejectReason: string): Promise<AxiosResponse<ApiResult>>  {
    const payload = {
      rejectionReason: rejectReason,
    }
    return httpService.post(
      `https://${baseUrl()}/vehicles/vinReviews/${id}/reject`,
      payload
    )
  },
}
