<template>
  <BaseTableWrapper
    ref="tableWrapper"
    :columns="columns"
    :table-init="tableInit"
  >
    <h1 class="page-header">Vehicle Reviews</h1>
  </BaseTableWrapper>
</template>

<script>
import vehicles from '@/services/vehicles'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'
import { datePredefined } from '@/utils/predefined'
import VehicleReviewTableDetail from '@/components/VehicleReviewTableDetail.vue'
import { VINReviewStatusKeys, VINReviewTypes } from '@/utils/enum'
import { EventBus } from '@/utils/event-bus'
import Vue from 'vue'

const reviewStatusMap = [
  { text: 'Pending', value: VINReviewStatusKeys.Pending },
  { text: 'Rejected', value: VINReviewStatusKeys.Rejected },
  { text: 'Approved', value: VINReviewStatusKeys.Accepted },
  { text: 'Cancelled', value: VINReviewStatusKeys.Cancelled },
]

const submissionTypeMap = [
  { text: 'New', value: VINReviewTypes.Create },
  { text: 'Edit', value: VINReviewTypes.Update },
]

const categories = [
  {
    _t_id: 'ec5251b1-24c0-45d3-9615-7e159f763e91',
    text: 'Review Status',
    prop: 'vinReviewStatusKey',
    values: deepClone(reviewStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: '5d200703-76f0-425d-bfdf-b89c37f3421f',
    text: 'Submission Type',
    prop: 'vinReviewTypeKey',
    values: deepClone(submissionTypeMap),
    type: 'text',
    method: 'or',
  },
]

export default {
  components: {
    BaseTableWrapper,
    VehicleReviewTableDetail,
  },
  metaInfo() {
    return {
      title: 'Vehicle Reviews',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableProps: {},
      tableInit: {
        detail: VehicleReviewTableDetail,
        detailKeyId: 'id',
        tableId: 'vehicle_reviews_tv',
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        addNewEnabled: false,
        currentPage: 1,
        perPage: 10,
        isDetailed: false,
        columns: [
          {
            _t_id: '57923e0a-6dc4-442c-bcd5-76e6bf5a0742',
            prop: 'id',
            text: 'ID',
            sort: true,
            filter: true,
            type: 'number',
            filterType: 'eq',
            sortProp: 'id',
            defaultSort: true,
          },
          {
            _t_id: '0f716cef-3b1c-4ead-be0a-4de6a67d4169',
            prop: 'vinReviewStatusKey',
            text: 'Status',
            sort: true,
            filter: false,
            computedText: (item) => this.mapCategoryText(reviewStatusMap, item),
            type: 'text',
            filterType: 'contains',
          },
          {
            _t_id: '2c4da62d-951d-4a61-8a78-f2450c5f3d26',
            prop: 'vinReviewTypeKey',
            text: 'Type',
            sort: true,
            filter: false,
            computedText: (item) =>
              this.mapCategoryText(submissionTypeMap, item),
            type: 'text',
            filterType: 'contains',
          },
          {
            _t_id: 'e3a404a6-bc47-49c6-8209-2655548a0a83',
            prop: 'updatedAt',
            text: 'Submitted Date',
            sort: true,
            filter: true,
            type: 'date',
            filterType: 'eq',
            sortProp: 'updatedAt',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toFormat('MM/dd/yyyy t'),
            predefined: deepClone(datePredefined),
            detail: false,
          },
          {
            _t_id: 'de6a268f-bf20-40b9-a7c0-d1e0652df7cc',
            prop: 'reviewedAt',
            text: 'Review Date',
            sort: true,
            filter: true,
            type: 'date',
            filterType: 'eq',
            sortProp: 'reviewedAt',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toFormat('MM/dd/yyyy t'),
            predefined: deepClone(datePredefined),
            detail: false,
          },
          {
            _t_id: 'caf78f7a-7253-4dbf-9394-ba7432871cf0',
            prop: 'companyName',
            text: 'Operator',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            filterProp: 'vehicle/company/name',
            sortProp: 'vehicle/company/name',
          },
          {
            _t_id: 'fa16db3f-f1bb-4db8-9a4f-d98e2e8a5b0c',
            prop: 'vehicleName',
            text: 'Vehicle Name',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            sortProp: 'vehicleName',
          },
          {
            _t_id: '008298bf-313f-47cf-b72a-8074f8c7e2e3',
            prop: 'vehicleTypeLabel',
            text: 'Vehicle Type',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            filterProp: 'vehicle/vehicleType/label',
            sortProp: 'vehicle/vehicleType/label',
          },
          {
            _t_id: '8eb16b78-6280-4a31-8a1b-051fce5bae76',
            prop: 'licensePlate',
            text: 'License Plate',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            filterProp: 'vehicle/licensePlate',
            sortProp: 'vehicle/licensePlate',
          },
          {
            _t_id: 'b51da133-4570-4675-b1fb-d59e342d25fc',
            prop: 'vinNumber',
            text: 'VIN',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            sortProp: 'vinNumber',
          },
          {
            _t_id: '753cefe9-d175-4c97-9523-37efc8ae6b9d',
            prop: 'vehicleMake',
            text: 'Make',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            sortProp: 'vehicleMake',
          },
          {
            _t_id: 'e2729c86-2084-4e0c-96c9-7654380181b7',
            prop: 'vehicleModel',
            text: 'Model',
            sort: true,
            filter: true,
            type: 'test',
            filterType: 'contains',
            sortProp: 'vehicleModel',
          },
          {
            _t_id: '80b21b5f-4a8a-4f47-96c1-73a159113418',
            prop: 'vehicleYear',
            text: 'Year',
            sort: true,
            filter: true,
            type: 'number',
            filterType: 'eq',
            sortProp: 'vehicleYear',
          },
        ],
        categories,
        collection: 'VINReviews',
        action: (params) => vehicles.getVINReviews(params),
      },
    }
  },
  mounted() {
    this.tableProps = this.$refs.tableWrapper.tableProps

    EventBus.$on('vehicle-review-tv-update-row', (id, newStatus) =>
      this.updateRow(id, newStatus)
    )
  },
  methods: {
    mapCategoryText(map, item) {
      const match = map.find((status) => status.value === item)
      if (match) {
        return match.text
      }
      return item
    },

    updateRow(id, newStatus, newRejectReason) {
      const indexToUpdate = this.tableProps.list.findIndex(
        (vehicleReview) => vehicleReview.id === id
      )
      const now = DateTime.local()
      if (indexToUpdate >= 0) {
        const currentRow = {
          ...this.tableProps.list[indexToUpdate],
          vinReviewStatusKey: newStatus,
          reviewedAt: now,
          rejectReason: newRejectReason,
        }
        Vue.set(this.tableProps.list, indexToUpdate, currentRow)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .status-filters .status-filter-select {
  max-width: 350px !important;
}

::v-deep .layout.status-filters.row.justify-space-between {
  justify-content: end;
}
</style>
