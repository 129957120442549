<template>
  <v-container>
    <v-layout>
      <v-flex>
        <div>
          <b>Vehicle ID:</b>
          {{ row.vehicleId }}
        </div>
        <div>
          <b>Garage Name:</b>
          {{ row.garageName }}
        </div>
        <div>
          <b>Submitted By:</b>
          {{ row.userName }} | {{ row.companyName }}
        </div>
        <div
          v-if="row.vinReviewStatusKey === pendingStatus"
          style="max-width: 500px"
        >
          <div class="align-right">
            <v-btn
              :id="`${row.id}-vehicle-review-detail-button-accept`"
              :loading="loading"
              color="error"
              @click="rejectVehicleReview"
            >
              Reject
            </v-btn>
            <v-btn
              :id="`${row.id}-vehicle-review-detail-button-reject`"
              :loading="loading"
              color="primary"
              @click="acceptVehicleReview"
            >
              Approve
            </v-btn>
          </div>
          <div>
            <b>Reject Reason</b>
          </div>
          <CRTextArea
            id="reject-reason-text-area"
            ref="reject-reason"
            v-model="rejectReason"
            :placeholder="rejectReasonDefault"
            :rows="5"
            auto-grow
          />
        </div>
        <br />
        <div v-if="row.vinReviewStatusKey === rejectedStatus">
          <div>
            <b>Reject Reason:</b>
          </div>
          <div style="max-width: 500px">
            {{ row.rejectReason }}
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import vehicles from '@/services/vehicles'
import { VINReviewStatusKeys } from '@/utils/enum'
import { EventBus } from '@/utils/event-bus'
import { mapActions } from 'vuex'

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      rejectReason: '',
      rejectReasonDefault:
        'Unfortunately, we cannot verify that this vehicle is part of your fleet. Please contact your insurance agent to request an updated Fleet Sheet/Vehicle Schedule that includes all vehicles on your current COI. Once received, either forward that email or send the attachment directly to partnersupport@charterup.com for review.',
      expanded: this.props.expanded,
      pendingStatus: VINReviewStatusKeys.Pending,
      rejectedStatus: VINReviewStatusKeys.Rejected,
    }
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    closeCard() {
      this.props.expanded = false
    },

    async acceptVehicleReview() {
      this.loading = true
      const response = await vehicles.acceptVinReview(this.row.id)

      if (response?.status === 200) {
        this.showAlert({
          type: 'success',
          message: `Vehicle approved!`,
        })

        EventBus.$emit('vehicle-review-tv-update-row', this.row.id, 'Approved')

        this.props.expanded = false
      } else {
        this.showAlert({
          type: 'error',
          message: 'Error approving vehicle, please try again',
        })
        this.loading = false
      }
    },

    async rejectVehicleReview() {
      this.loading = true

      const rejectReason =
        this.rejectReason.trim() === ''
          ? this.rejectReasonDefault
          : this.rejectReason

      const response = await vehicles.rejectVINReview(this.row.id, rejectReason)

      if (response?.status === 200) {
        this.showAlert({
          type: 'success',
          message: `Vehicle rejected`,
        })

        EventBus.$emit(
          'vehicle-review-tv-update-row',
          this.row.id,
          'Rejected',
          rejectReason
        )

        this.props.expanded = false
      } else {
        this.showAlert({
          type: 'error',
          message: 'Error rejecting vehicle, please try again',
        })
        this.loading = false
      }
    },
  },
}
</script>
